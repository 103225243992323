<template>
  <b-card>
    <b-card-title>
      <h3>Actualizar localidades</h3>
    </b-card-title>
    <b-card-body>
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
      ></loading>
      <b-row>
        <b-form @submit="onSubmit" inline>
          <b-form-group>
            <b-form-file
              v-model="form.file"
              accept=".xls, .xlsx, .csv"
              ref="file"
              @change="onChangeFile"
            >
            </b-form-file>
          </b-form-group>
          <b-button
            ref="submitButton"
            class="ml-2"
            variant="primary"
            type="submit"
            :disabled="!form.file"
          >
            Procesar
          </b-button>
        </b-form>
      </b-row>
      <b-row>
        <div class="alert alert-info mt-2" role="alert" ref="alert">
          <small>
            El archivo a subir debe tener el mismo formato con el cual se
            exportó.
          </small>
        </div>
      </b-row>
      <b-row>
        <ul>
          <li
            style="color: #ca0d0d; font-weight: bold"
            v-for="error in errors"
            :key="error"
          >
            {{ error }}
          </li>
        </ul>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BCardTitle,
  BCardBody,
  BRow,
  BForm,
  BFormGroup,
  BFormFile,
  BButton,
} from "bootstrap-vue";

import * as XLSX from "xlsx";
import LocationService from "@/services/LocationService";
import * as _ from "lodash";

export default {
  name: "LocationUpload",
  components:{
    BCard,
    BCardTitle,
    BCardBody,
    BRow,
    BForm,
    BFormGroup,
    BFormFile,
    BButton,
  },
  data() {
    return {
      isLoading: null,
      form: {
        file: null,
      },
      locations: null,
      errors: [],
    };
  },
  methods: {
    /**
     *
     */
    onSubmit(event) {
      this.errors = [];
      event.preventDefault();
      this.isLoading = true;
      const readtWithString = (wb, name) => {
        const elements = XLSX.utils
          .sheet_to_json(wb.Sheets[name])
          .map((item) => {
            const val = {};
            Object.keys(item).forEach((key) => {
              const key2 = key.toLowerCase().replace(/\s/g, "");
              val[key2] = String(item[key]).trim();
            });
            return val;
          });
        return elements;
      };
      const file = this.form.file;
      const reader = new FileReader();
      reader.onload = (e) => {
        // validate
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const locations = readtWithString(wb, wb.SheetNames[0]);
        LocationService.upload(
          locations.map((item) => {
            return _.pick(item, ["idlocalidad", "idprovincia", "localidad"]);
          })
        )
          .then(() => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Información`,
                text: `Localidades actualizadas`,
                icon: "InfoIcon",
                variant: "info",
              },
            });
            this.$router.push("/dashboard/locations");
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
            if (error.response && error.response.data) {
              this.errors = Object.values(error.response.data.errors);
            }
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                text: `Error al tratar de procesar`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      };
      reader.readAsBinaryString(file);
    },

    /**
     *
     */
    onChangeFile() {},

    /**
     *
     */
    onSave() {},
  },
};
</script>
